@font-face {
  font-family: 'Orleans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Orleans/Orleans-Bold.woff2') format('woff2'),
    url('/fonts/Orleans/Orleans-Bold.ttf') format('truetype'),
    url('/fonts/Orleans/Orleans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'OptumSans';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/OptumSans/WOFF2/OptumSans-Regular.woff2') format('woff2'),
    url('/fonts/OptumSans/TTF/OptumSans-Regular.ttf') format('truetype'),
    url('/fonts/OptumSans/OTF/OptumSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'OptumSans';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/OptumSans/WOFF2/OptumSans-RegularItalic.woff2')
      format('woff2'),
    url('/fonts/OptumSans/TTF/OptumSans-RegularItalic.ttf') format('truetype'),
    url('/fonts/OptumSans/OTF/OptumSans-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'OptumSans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/OptumSans/WOFF2/OptumSans-Bold.woff2') format('woff2'),
    url('/fonts/OptumSans/TTF/OptumSans-Bold.ttf') format('truetype'),
    url('/fonts/OptumSans/OTF/OptumSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'OptumSans';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/OptumSans/WOFF2/OptumSans-BoldItalic.woff2') format('woff2'),
    url('/fonts/OptumSans/TTF/OptumSans-BoldItalic.ttf') format('truetype'),
    url('/fonts/OptumSans/OTF/OptumSans-BoldItalic.otf') format('opentype');
}
